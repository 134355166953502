import React from "react"
import { graphql } from "gatsby";
import Image from './Image'

export const MEDIA_IMAGE = 'IMAGE'
export const MEDIA_VIDEO = 'VIDEO'
export const CONTENTFUL_VIDEO = 'video'

export const Video = ({ src }) => (
	<video
		src={src}
		autoPlay
		playsInline
		muted
		loop
	/>
)

export const MediaImage = ({ size, image, proheight, alt="Harrys London"  }) => {
	const imageData = image && image[size]

	return (
		imageData ? <Image image={imageData} proheight={proheight} alt={alt} /> : null
	)
}

export const ShopifyVideo = ({ sources, proheight, ...props}) => {
	return (
		<div className={`media-video ${proheight ? 'proheight' : ''}`}>
			<Video src={sources[1]?.url || ""} />
		</div>
	)
}

export const ShopifyMedia = ({ mediaContentType, thumbnail, large, hires, ...props }) => {

	return mediaContentType === MEDIA_IMAGE ? (
		<MediaImage {...props} />
	) : mediaContentType === MEDIA_VIDEO ? (
		<ShopifyVideo {...props} />
	) : (
		<MediaImage {...props} image={{ thumbnail, large, hires }} />
	)
}

export const ContentfulVideo = ({ file, proheight, ...props}) => {
	return (
		<div className={`media-video ${proheight ? 'proheight' : ''}`}>
			<Video src={file?.url || ""} />
		</div>
	)
}

export const ContentfulMedia = (props) => {
	return props.file?.contentType.includes(CONTENTFUL_VIDEO) ? (
		<ContentfulVideo {...props} />
	) : (
		<MediaImage {...props} />
	)
}

export const MediaFragments = graphql`

	fragment ContentfulMediaFragment on ContentfulAsset {
		contentful_id
		file {
			url
			contentType
		}
		thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 1080)
		large: gatsbyImageData(layout: FULL_WIDTH)
		hires: gatsbyImageData(layout: CONSTRAINED, width: 3840, quality: 100, formats: [JPG])
	}
`