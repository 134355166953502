// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../wrappers/Layout"
import useSettings from "../hooks/useSettings"
import Hero from "../components/Hero"

const NotFoundPage = ({ location }) => {
	const { defaultShareImage, notFoundCopy404 } = useSettings()

	return (
		<Layout title="404 | Harrys London" location={location}>
			<Hero
				mediaLandscape={[defaultShareImage]}
				copy={notFoundCopy404}
			/>
		</Layout>
	)
}

export default NotFoundPage
